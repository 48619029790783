<template>
  <div>
    <v-overlay :value="loading" :absolute="true" v-if="$route.name !== 'reportPrint'">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <DispatchersReportTemplate v-if="$route.name === 'reportPrint'"
                               :entries="$route.params.report_number > 0 ? null : loads"
                               :totals="totalsCounts"
                               :reportData="reportData"
                               :from="from"
                               :to="to"
                               :dispatcherName="dispatcherName"
                               :currentDateTime="currentDateTime"
                               :key="'reportTemplateKey' + loads.length"
                               :companyId="companyId"
    />
    <v-card v-else>
      <v-card-title v-if="dispatcherId > 0 && !hideTitle" :style="{ backgroundColor: $root.currentTheme.background }">
        <v-btn @click="$root.route('/reports/dispatchers')" icon><v-icon>mdi-chevron-left</v-icon></v-btn>
        <a @click="$root.onShowUserPreview(dispatcherId, dispatcherName)">{{dispatcherName}}</a>
      </v-card-title>
          <DispatchersTotals :from="from"
                              :to="to"
                              :key="from+to"
                              :dispatcherId="dispatcherId"/>
      <v-divider/>
      <div id="reportCardId">

      <v-layout v-if="($root.isFullFinancialDataAllowedToView || $root.isDispatcherFinancialDataAllowedToView) && dispatcherId > 0 && !hideActionsBar">
        <v-flex xs12 class="text-right">
          <ReportActions
            :userId="dispatcherId"
            :overrideType="$store.getters.overriddenFieldsTypes.LOADS"
            overrideFieldName="DispatchersSalary"
            :from="from"
            :to="to"
            :paramsType="!$root.isFullFinancialDataAllowedToView && $root.isDispatcherFinancialDataAllowedToView ? 'dispatchers' : $route.params.type"
            :selectedItems="selectedItems"
            :isSelectedHaveNotPayed="isSelectedHaveNotPayed"
            :isSelectedHavePayed="isSelectedHavePayed"
            @create-report-group="createReport"
            @mark-selected-as-paid="markSelectedAsPaid"
            @mark-selected-as-not-paid="markSelectedAsNotPaid"
            @mark-selected-as-payed-and-create-report-group="markSelectedAsPayedAndCreateReportGroup"
            @show-saved-reports="$root.onShowReportsModal"
          />
        </v-flex>
      </v-layout>
      <v-layout v-if="dispatcherId > 0">
        <v-flex shrink>
          <FiltersPanel
            ref="filtersPanel"
            prefix="dispatchersReport"
            outlined
            :isExpansionPanel="$root.isMobile"
            :loading="loading"
            :items="filtersItems"
            :key="filtersKey"
            @filterBrokerKeyUp="filterBrokerSearch"
            @change="onFilterChanged"/>
        </v-flex>
        <v-flex>
          <v-text-field
            class="mt-1"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            single-line
            outlined dense
            hide-details
          />
        </v-flex>
      </v-layout>
      <!--<hr/>
      {{loads}}
      <hr/>-->
      <v-list v-if="$root.isMobile">
        <v-list-item-group
          v-model="selectedItems"
          multiple
          active-class=""
        >
          <template v-for="item in loads">
            <v-list-item :key="item.id">
              <template v-slot:default="{ active }">
                <v-list-item-icon>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-icon>
                <v-list-item-content @click="$root.onShowLoadPreview(item.id)">
                  <v-list-item-subtitle>{{item.startedPST}}</v-list-item-subtitle>
                  <LoadLocationsStartEnd
                    :load-name="item.name"
                    :max-width="'200px'"
                    :hideCopyIcons="true"
                  />
                  <v-list-item-subtitle>{{item.endedPST}}</v-list-item-subtitle>
                  <template v-if="item.isShared">
                    <br/>Shr/w:&nbsp;
                    <template v-for="(dispatcher, dispatcherIndex) in item.sharedWith">
                      <v-chip small class="text-no-wrap" :key="'sharedWith'+dispatcherIndex">
                        <a @click.stop="$router.push('/reports/dispatchers/'+dispatcher.id)">{{dispatcher.firstName[0]}}{{dispatcher.lastName[0]}}</a>&nbsp;
                      </v-chip>
                    </template>
                  </template>
                </v-list-item-content>
                <v-list-item-action>
                  {{parseInt(item.distanceInMiles).toLocaleString()}}{{$t('milesShort')}}<br/>
                  <LoadRateSnippet v-if="item" :load="item"/>
                </v-list-item-action>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
      <v-data-table
        v-else-if="!isByInvoices && dispatcherId > 0"
        :headers="headers"
        :items="loads"
        :search="search"
        height="calc(100vh - 200px)"
        fixed-header
        :items-per-page="-1"
        :hide-default-footer="true"
        v-model="selectedItems"
        :show-select="$root.isFullFinancialDataAllowedToView || $root.isDispatcherFinancialDataAllowedToView"
        @item-selected="onItemSelected"
        @toggle-select-all="onItemSelected"
        @click:row="$root.onShowLoadPreview($event.id)"
      >
        <template v-slot:item.id="{ item }">
          {{item.id}}
          <template v-if="item.isShared">
            <br/>Shr/w:&nbsp;
            <template v-for="(dispatcher, dispatcherIndex) in item.sharedWith">
              <v-chip small class="text-no-wrap" :key="'sharedWith'+dispatcherIndex">
                <a @click.stop="$router.push('/reports/dispatchers/'+dispatcher.id)">{{dispatcher.firstName[0]}}{{dispatcher.lastName[0]}}</a>&nbsp;
              </v-chip>
            </template>
          </template>
          <div class="text-no-wrap">
            <v-icon small v-if="item.status.phaseIcon" v-html="item.status.phaseIcon" :color="item.status.phaseColor" class="mr-1"/>
            <v-icon small v-if="item.status.stageIcon" v-html="item.status.stageIcon" :color="item.status.stageColor" class="mr-1"/>
            <v-icon small v-if="item.isReturnToHQ === 1" color="success" class="mr-1">mdi-redo-variant</v-icon>
          </div>
        </template>

        <template v-slot:item.started="{ item }">
          <div class="text-no-wrap">{{item.startedPST}}</div>
          <div class="text-no-wrap">{{item.endedPST}}</div>
        </template>

        <template v-slot:item.name="{ item }">
          <v-layout class="text-no-wrap">
            <v-flex shrink>
              <LoadLocationsTooltip :load-fields="item"></LoadLocationsTooltip>
            </v-flex>
            <v-flex grow class="pl-2">
              <LoadLocationsStartEnd
                :load-name="item.name"
                :max-width="'200px'"
                :gmapsDirectionsBtn="true"
                @open-gmaps-directions="() => { $root.openGmapsDirections(item) }"
                @show-map-modal="() => { $emit('show-map-modal', item) }"
                @show-map-div="(event) => { $emit('show-map-modal', item, true, event) }"
              />
            </v-flex>
          </v-layout>
        </template>

        <template v-slot:item.rate_per_mile="{ item }">
          {{parseInt(item.distanceInMiles).toLocaleString()}}{{$t('milesShort')}}<br/>
          <!--<template v-if="item.isShared">
            ${{parseFloat(parseFloat(getTotalRate(item, true)/(item.sharedWith.length+1))/parseInt(item.distanceInMiles)).round(2).toLocaleString()}}
          </template>
          <template v-else>-->
            ${{parseFloat(parseFloat(getTotalRate(item, true))/parseInt(item.distanceInMiles)).round(2).toLocaleString()}}
          <!--</template>-->
        </template>

        <template v-slot:item.uid_load_number="{ item }">
            <span class="text-no-wrap">
              {{item.loadNumber}}
              <v-btn v-if="item.loadNumber" small icon @click.stop="$root.copyToClipboard(item.loadNumber)"><v-icon small>mdi-content-copy</v-icon></v-btn>
            </span>
          <br/>
          <span class="text-no-wrap">
              {{item.uid}}
              <v-btn v-if="item.uid" small icon @click.stop="$root.copyToClipboard(item.uid)"><v-icon small>mdi-content-copy</v-icon></v-btn>
            </span>
        </template>

        <template v-slot:item.loadRate="{ item }">
          <LoadRateSnippet v-if="item" :load="item"/>
        </template>

        <template v-slot:item.drivers_trucks="{ item }">
          <template v-if="item.brokerName">
            <div v-if="$root.isAccessRightAllowView($store.getters.menuRights['Broker'])"
                 style="width:100px; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
              <a @click.stop="$root.route('/user/page/' + item.broker)">{{item.brokerName}}</a>
            </div>
            <span v-else>{{item.brokerName}}</span>
          </template>
          <TruckDriversSnippet :trucks="item.trucks" :isDriverOO="item.isDriverOwnerOperator === 1" :truckOO="item.truckOOData" :driverOO="item.driverOOData" :load-id="item.id"/>
        </template>

        <template v-slot:item.dispatcherRate="{ item }">
          <v-layout>
            <v-flex class="pt-1">
              ${{parseFloat(item.dispatcherRate).round(2).toLocaleString()}}
            </v-flex>
            <v-flex>
              <OverriddenFieldsButton
                :key="$root.overrideFieldKey"
                :userId="dispatcherId"
                :itemType="$store.getters.overriddenFieldsTypes.LOADS"
                :itemId="item.id"
                prefix="$"
                :isTime="false"
                fieldName="dispatcherRate"
                :currentNote="item.dispatcherRateNote"
                :currentValue="item.dispatcherRate"
                :showCancel="item.isOverriddenDispatcherRate"
                @change="fetchData"
              />
            </v-flex>
            </v-layout>
            <v-layout>
              <v-flex class="pt-1">
                {{item.dispatchersRatePercent}}%
              </v-flex>
              <v-flex>
                <OverriddenFieldsButton
                  :key="$root.overrideFieldKey"
                  :userId="dispatcherId"
                  :itemType="$store.getters.overriddenFieldsTypes.LOADS"
                  :itemId="item.id"
                  prefix="%"
                  :isTime="false"
                  fieldName="dispatchersRatePercent"
                  :currentNote="item.dispatchersRatePercentNote"
                  :currentValue="item.dispatchersRatePercent"
                  :showCancel="item.isOverriddenDispatchersRatePercent"
                  @change="fetchData"
                />
              </v-flex>
            </v-layout>
        </template>

        <template v-slot:item.dispatcherRateFromBonus="{ item }">
          <v-layout>
            <v-flex class="pt-1">
              ${{parseFloat(item.dispatcherRateFromBonus).round(2).toLocaleString()}}
            </v-flex>
            <v-flex>
              <OverriddenFieldsButton
                :key="$root.overrideFieldKey"
                :userId="dispatcherId"
                :itemType="$store.getters.overriddenFieldsTypes.LOADS"
                :itemId="item.id"
                prefix="$"
                :isTime="false"
                fieldName="dispatcherRateFromBonus"
                :currentNote="item.dispatcherRateFromBonusNote"
                :currentValue="item.dispatcherRateFromBonus"
                :showCancel="item.isOverriddenDispatcherRateFromBonus"
                @change="fetchData"
              />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex class="pt-1">
              {{item.dispatcherPaymentPercentFromBonus}}%
            </v-flex>
            <v-flex>
              <OverriddenFieldsButton
                :key="$root.overrideFieldKey"
                :userId="dispatcherId"
                :itemType="$store.getters.overriddenFieldsTypes.LOADS"
                :itemId="item.id"
                prefix="%"
                :isTime="false"
                fieldName="dispatcherPaymentPercentFromBonus"
                :currentNote="item.dispatcherPaymentPercentFromBonusNote"
                :currentValue="item.dispatcherPaymentPercentFromBonus"
                :showCancel="item.isOverriddenDispatcherPaymentPercentFromBonus"
                @change="fetchData"
              />
            </v-flex>
          </v-layout>
        </template>

        <template v-slot:item.dispatcherRateTotal="{ item }">
          <v-layout v-if="!item.isPaid">
            <v-flex>
              ${{parseFloat(item.dispatcherRateTotal).round(2).toLocaleString()}}
            </v-flex>
            <v-flex class="text-right pt-0" v-if="item.isVerified && $root.isFullFinancialDataAllowedToView" >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on"
                         class="ml-1"
                         x-small
                         @click.stop="onPaid(item.id, dispatcherId, 'DispatchersSalary', $store.getters.overriddenFieldsTypes.LOADS, item.dispatcherRateTotal)">
                    <v-icon small left color="success">mdi-currency-usd</v-icon>{{$t('Reports.Paid')}}
                  </v-btn>
                </template>
                <span>Mark as Paid</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex>
              <v-chip v-if="item.isPaid" class="mt-1" small color="success">
                <div class="text-no-wrap">
                  <v-icon small left
                          v-if="$root.isFullFinancialDataAllowedToView"
                          @click.stop="onPaid(item.id, dispatcherId, 'DispatchersSalary', $store.getters.overriddenFieldsTypes.LOADS, item.dispatcherRateTotal, item.paidDateTime, item.paidNote)">mdi-pencil</v-icon>
                  {{$t('Reports.Paid')}}
                  &nbsp;${{parseInt(item.dispatcherRateTotal).toLocaleString()}}
                  <v-icon small right color="error"
                          v-if="$root.isFullFinancialDataAllowedToView"
                          @click.stop="resetPayment(dispatcherId, $store.getters.overriddenFieldsTypes.LOADS, item.id, 'DispatchersSalary')">mdi-close</v-icon>
                </div>
              </v-chip>
            </v-flex>
            <v-flex v-if="item.isPaid && item.paidNote">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" style="margin-top: -2px;"><v-icon>mdi-information</v-icon></v-btn>
                </template>
                <span>{{item.paidNote}}</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
          <div class="text-no-wrap" v-if="item.isPaid">
            <label class="caption text-no-wrap">{{item.paidDateTime}}</label>
          </div>
          <template v-if="item.finished === 2"><v-chip color="warning" class="mb-1" small>TONU</v-chip></template>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-layout v-if="$root.isFullFinancialDataAllowedToView">
            <v-flex shrink>
              <template>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn  v-on="on"
                            :loading="item.loading"
                            :disabled="!item.isChecked"
                            @click.stop="onReportToggleChecked(item, item.id, dispatcherId, $store.getters.overriddenFieldsTypes.LOADS, 100)"
                            icon
                            small>
                      <v-icon :color="item.isVerified ? 'success': null">mdi-check-all</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{item.isVerified ? ($t('Reports.MarkedAsVerified') + (item.verifiedDateTime ? ': ' + item.verifiedDateTime : '')) : $t('Reports.MarkAsVerified')}}
                  </span>
                </v-tooltip>
              </template>
            </v-flex>
            <v-flex shrink>
              <template>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn  v-on="on"
                            :loading="item.loading"
                            @click.stop="onReportToggleChecked(item, item.id, dispatcherId, $store.getters.overriddenFieldsTypes.LOADS, 0)"
                            icon
                            small>
                      <v-icon small :color="item.isChecked ? 'success': null">mdi-check-decagram</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{item.isChecked ? ($t('Reports.MarkedAsChecked') + (item.checkedDateTime ? ': ' + item.checkedDateTime : '')) : $t('Reports.MarkAsChecked')}}
                  </span>
                </v-tooltip>
              </template>
            </v-flex>
            <!--<LoadVerificationButtons :item="item" small @fetch-data="fetchData"/>-->
            <v-flex v-if="item.isOverrideEditLock === 1" shrink>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn small icon v-on="on">
                    <v-icon small color="error">mdi-lock-open-variant</v-icon>
                  </v-btn>
                </template>
                <span>{{$t('Loads.EditingIsUnlocked')}}</span>
              </v-tooltip>
            </v-flex>
            <v-flex v-for="invoiceItem in item.invoicesArray" :key="'inv'+invoiceItem.id" shrink>
              <template v-if="invoiceItem.invoiceReceivedDate > 0 && $root.isInvoicesViewAllowed">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn small icon v-on="on" :color="$root.getInvoiceTypeColorByStatus(invoiceItem.invoiceStatus)" @click.stop="$root.onShowInvoicePreview(invoiceItem.invoiceId, invoiceItem.invoiceNumber)"><v-icon small>fa-file-invoice-dollar</v-icon></v-btn>
                  </template>
                  <span>
                    {{$t('Loads.Invoice')}} #{{invoiceItem.invoiceNumber}} ${{invoiceItem.invoiceTotal}}&nbsp;<strong>{{invoiceItem.invoiceStatus}}</strong>
                    <template v-if="invoiceItem.amountUnderpaid > 0">
                      (<strong>{{$t('Invoices.amountUnderpaid')}}</strong>:&nbsp;${{invoiceItem.amountUnderpaid}})
                    </template>
                  </span>
                </v-tooltip>
              </template>
              <template v-else-if="!invoiceItem.invoiceReceivedDate && $root.isInvoicesViewAllowed">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn small icon v-on="on" :color="invoiceItem.invoiceDueLeftSeconds < 0 ? 'red' : 'warning'" @click.stop="$root.onShowInvoicePreview(invoiceItem.invoiceId, invoiceItem.invoiceNumber)"><v-icon small>fa-file-invoice-dollar</v-icon></v-btn>
                  </template>
                  <span v-if="invoiceItem.invoiceDueLeftSeconds < 0">{{$t('Loads.Invoice')}} #{{invoiceItem.invoiceNumber}} ${{invoiceItem.invoiceTotal}}&nbsp;{{$t('Invoices.Overdue')}}:&nbsp;-{{Math.ceil($root.secondsToHms(invoiceItem.invoiceDueLeftSeconds).hours / 24)}} d.</span>
                  <span v-if="invoiceItem.invoiceDueLeftSeconds > 0">{{$t('Loads.Invoice')}} #{{invoiceItem.invoiceNumber}} ${{invoiceItem.invoiceTotal}}&nbsp;{{$t('Invoices.DueDate')}}:&nbsp;+{{Math.ceil($root.secondsToHms(invoiceItem.invoiceDueLeftSeconds).hours / 24)}} d.</span>
                </v-tooltip>
              </template>
            </v-flex>
            <v-flex v-if="item.invoicesArray.length === 0" shrink>
                <v-btn icon small disabled><v-icon small class="mr-1">fa-file-invoice-dollar</v-icon></v-btn>
            </v-flex>
            <v-flex shrink>
              <template v-if="item.bid">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn small icon v-on="on" @click.stop="$root.route('/bids/'+item.bid)"><v-icon small>mdi-file-question</v-icon></v-btn>
                  </template>
                  <span>Bid #{{item.bid}}</span>
                </v-tooltip>
              </template>
            </v-flex>
            <v-flex shrink>
              <template>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn small icon v-on="on" @click.stop="onShowDocuments(item)">
                      <v-icon small class="mr-1" :color="item.isAllRequiredDocsUploaded ? 'success' : 'warning'">mdi-file-multiple</v-icon>
                    </v-btn>
                  </template>
                  <span v-if="item.isAllRequiredDocsUploaded">{{$t('Loads.uploadDocuments')}}</span>
                  <span v-else>
                    <template v-for="(md, index) in item.missingDocs">
                      <span :key="'missingDocs_'+item.id+index" v-if="index === 'loadPhotos'">{{$t('Loads.LoadPhotos')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                      <span :key="'missingDocs_'+item.id+index" v-if="index === 'rateCon'">{{$t('Loads.RateCon')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                      <span :key="'missingDocs_'+item.id+index" v-if="index === 'do'">{{$t('Loads.DeliveryOrder')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                      <span :key="'missingDocs_'+item.id+index" v-if="index === 'pod'">{{$t('Loads.POD')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}<br/></span>
                      <span :key="'missingDocs_'+item.id+index" v-if="index === 'bol'">{{$t('Loads.BOL')}}:&nbsp; {{md ? $root.t('Yes') : $root.t('No')}}</span>
                    </template>
                  </span>
                </v-tooltip>
              </template>
            </v-flex>
            <v-flex shrink>
              <template>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn small icon v-on="on" @click.stop="onShowExpenses(item.expensesJSON, item.finished, item.id, item.trucks)">
                      <v-icon small class="mr-1" :color="expensesColor(item)">fa-coins</v-icon>
                    </v-btn>
                  </template>
                  <span>{{!item.isPendingExpenses ? $t('Loads.ExpensesTitle') : $t('Loads.ExpensesHasPending')}}</span>
                </v-tooltip>
              </template>
            </v-flex>
            <v-flex shrink>
              <template>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn small icon v-on="on" @click.stop="onShowFinancialData(item)">
                      <v-icon small class="mr-1">mdi-finance</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('Loads.FinancialData')}}</span>
                </v-tooltip>
              </template>
            </v-flex>
            <!--<v-flex v-if="dispatcherId > 0">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    class="ma-1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item @click="markAsPayed(item.id, dispatcherId)">
                    <v-list-item-icon><v-icon color="success">mdi-currency-usd</v-icon></v-list-item-icon>
                    <v-list-item-title> Mark as Payed</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="markAsNotPayed(item.id, dispatcherId)">
                    <v-list-item-icon><v-icon color="error">mdi-currency-usd-off</v-icon></v-list-item-icon>
                    <v-list-item-title> Mark as Not Payed</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-flex>-->
          </v-layout>
        </template>

      </v-data-table>
      </div>

      <v-dialog v-model="expensesModal" scrollable max-width="1000px">
        <v-card>
          <v-card-title>
            <h2>{{$t('Loads.ExpensesTitle')}}</h2><v-spacer></v-spacer><v-btn icon @click="expensesModal=false;"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <LoadExpenses :key="expensesModalKey"
                          :expensesArray="selectedExpenses"
                          :readOnly="!$root.isLoadsEditAllowed"
                          :isFinished="selectedFinishedField !== 0"
                          :loadId="selectedExpensesLoadId"
                          :preselectedTruck="selectedExpensesTruckId"
                          :preselectedDriver="selectedExpensesUserId"
                          @expensesSaved="fetchData()"
            ></LoadExpenses>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="documentsModal" scrollable>
        <v-card>
          <v-card-title>
            <h2>{{$t('Loads.uploadDocuments')}}</h2><v-spacer></v-spacer><v-btn icon @click="documentsModal=false;"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <LoadDocuments :key="documentsModalKey" :fields="selectedLoadFields" :readonly="true"></LoadDocuments>
        </v-card>
      </v-dialog>
    </v-card>

    <v-dialog v-model="financesModal" scrollable max-width="1000px">
      <v-card>
        <v-card-title>
          <h2>{{$t('Loads.FinancialData')}}</h2><v-spacer></v-spacer><v-btn icon @click="financesModal=false;"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <FinancialDataForLoad :loadId="selectedFinancesLoadId"
                              :expenses="selectedExpenses"
                              :key="financesModalKey"
                              :locations="selectedLoadLocations"
                              :isFullFinancialData="$root.isFullFinancialDataAllowedToView"
                              :isDispatcherFinancialData="$root.isDispatcherFinancialDataAllowedToView"
                              :isDriverFinancialData="$root.isDriverFinancialDataAllowedToView"
                              :excludeDispatcherPayment="parseInt(selectedLoadExcludeDispatcherPayment) === 1 ? true : false"
        />
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import reportsMixin from '../../mixins/reportsMixin.js';
import LoadExpenses from '../../components/LoadExpenses.vue';
import LoadDocuments from '../../components/LoadDocuments.vue';
import DispatchersReportTemplate from './DispatchersReportTemplate.vue';
import FiltersPanel from '../../components/FiltersPanel.vue';
import LoadLocationsTooltip from '../../components/LoadLocationsTooltip.vue';
import TruckDriversSnippet from '../../components/TruckDriversSnippet.vue';
import LoadVerificationButtons from '../../components/LoadVerificationButtons.vue';
import ReportActions from '../../components/ReportActions';
import DispatchersTotals from './DispatchersTotals';
import LoadLocationsStartEnd from '../../components/LoadLocationsStartEnd';
import OverriddenFieldsButton from '../../components/OverriddenFieldsButton';
import LoadRateSnippet from '../../components/LoadRateSnippet';
import FinancialDataForLoad from '../../components/FinancialDataForLoad';

export default {
  name: 'DispatcherReport',
  props: ['reportData', 'from', 'to', 'dispatcherId', 'selected', 'type', 'companyId', 'hideActionsBar', 'hideTitle'],
  components: { FinancialDataForLoad, LoadRateSnippet, LoadLocationsStartEnd, LoadLocationsTooltip, LoadExpenses, LoadDocuments, DispatchersReportTemplate, FiltersPanel, TruckDriversSnippet, LoadVerificationButtons, ReportActions, DispatchersTotals, OverriddenFieldsButton },
  mixins: [reportsMixin],
  data: function () {
    return {
      loading: false,
      isByInvoices: false,
      loads: [],
      search: '',
      selectedItems: [],
      expensesModal: false,
      expensesModalKey: new Date().getTime(),
      selectedExpenses: [],
      selectedExpensesLoadId: 0,
      selectedFinishedField: 0,
      selectedExpensesTruckId: 0,
      selectedExpensesUserId: 0,
      documentsModal: false,
      documentsModalKey: new Date().getTime(),
      selectedLoadFields: {},
      dispatcherName: '',
      financesModal: false,
      financesModalKey: new Date().getTime(),
      selectedFinancesLoadId: 0,
      selectedLoadExcludeDispatcherPayment: 0,
      selectedLoadLocations: [],
      currentDateTime: '',
      headers: [
        { text: '#', value: 'id' },
        { text: 'Started / Ended', value: 'started' },
        { text: 'Locations', value: 'name' },
        { text: 'Broker / Trucks / Drivers', value: 'drivers_trucks', sortable: false },
        { text: 'UID / Load Number', value: 'uid_load_number', sortable: false },
        { text: 'Load Rate', value: 'loadRate' },
        { text: 'Miles / Rate per mile', value: 'rate_per_mile' },
        { text: 'From Rate', value: 'dispatcherRate' },
        { text: 'From Bonus', value: 'dispatcherRateFromBonus' },
        { text: 'Salary', value: 'dispatcherRateTotal' },
        { text: '', value: 'paid', sortable: false },
        { text: '', value: 'actions', sortable: false }
      ]
    };
  },
  computed: {
    totalsCounts () {
      return this.$store.getters['reports/totalsCounts'];
    }
  },
  mounted () {
    this.$set(this, 'filtersItems', this.filtersItems.filter((el) => { return el.name !== 'filterDispatcher'; }));
    this.setDispatchersTotalsHeaders();

    if (this.type) {
      this.$route.params.type = this.type;
    }
    this.$root.overrideFieldCallback = this.fetchData;
    this.$root.setLastListPath(this.$route.path);
    this.$nextTick(() => {
      this.fetchData().then(() => {
        this.isFilterInitialized = true;
      });
    });
  },
  methods: {
    onShowFinancialData (loadData) {
      this.selectedFinancesLoadId = loadData.id;
      this.selectedExpenses = JSON.parse(loadData.expensesJSON);
      this.selectedLoadLocations = loadData.locationsJSON;
      this.selectedLoadExcludeDispatcherPayment = loadData.isExcludeDispatcherPayment
      this.financesModalKey = new Date().getTime();
      this.financesModal = true;
    },
    onFilterChanged (e) {
      let i = 0;
      let field = '';
      let value = '';
      const filtersFields = Object.keys(e);
      for (i = 0; i < filtersFields.length; i++) {
        field = filtersFields[i];
        value = Object.prototype.hasOwnProperty.call(e, field) ? e[field] : null;
        if (value !== '') {
          this[field] = Array.isArray(value) ? value.join(',') : value;
        }
      }
      if (this.isFilterInitialized) {
        this.fetchData();
      }
      this.isFilterInitialized = true;
    },
    getEmptyTotals () {
      this.$store.dispatch('reports/totalsCounts', {
        loadsCount: 0,
        totalLoadsRate: 0,
        totalAdditionalPayments: 0,
        totalLoadsRateWithAdditionalPayments: 0,
        totalAvgLoadsRatePerMile: 0,
        totalDispatcherPaymentFromRate: 0,
        totalDispatcherPaymentFromBonus: 0,
        totalDispatcherRate: 0
      });
      return this.$store.getters['reports/totalsCounts'];
    },
    onItemSelected () {
      this.$nextTick(() => {
        let selected = [];
        for (let i = 0; i < this.selectedItems.length; i++) {
          selected.push(parseInt(this.selectedItems[i]['id']));
        }
        this.$emit('selected', selected);
      });
    },
    onShowExpenses (expensesJSON, finishedField, loadId, trucksDrivers) {
      this.selectedExpensesLoadId = loadId;
      this.selectedExpenses = JSON.parse(expensesJSON) || [];
      this.selectedFinishedField = finishedField;
      this.selectedExpensesTruckId = 0;
      this.selectedExpensesUserId = 0;
      if (trucksDrivers && trucksDrivers.length > 0) {
        this.selectedExpensesTruckId = trucksDrivers[0].id;
        if (trucksDrivers[0].drivers && trucksDrivers[0].drivers.length > 0) {
          this.selectedExpensesUserId = trucksDrivers[0].drivers[0].id;
        }
      }
      this.expensesModalKey = new Date().getTime();
      this.expensesModal = true;
    },
    onShowDocuments (fields) {
      if (fields) {
        this.selectedLoadFields = fields;
        this.documentsModalKey = new Date().getTime();
        this.documentsModal = true;
      }
    },
    processLoad (loadData) {
      let load = loadData;
      // const locations = JSON.parse(response.loads[i].locationsJSON.replace(/\n/g, '\\n').replace(/\r/g, '\\r').replace(/\t/g, '\\t'));
      load.status = this.$root.getLoadStatus(load.locations, load.finished);

      // Process load item
      load.uid_load_number = load.loadNumber + ' ' + load.uid;
      load.drivers_trucks = '';

      for (let truck in load.trucks) {
        if (load.trucks[truck] && load.trucks[truck].truckName) {
          load.drivers_trucks += ' ' + load.trucks[truck].truckName;
        }
      }

      for (let driver in load.drivers) {
        if (load.drivers[driver] && load.drivers[driver].driverName) {
          load.drivers_trucks += ' ' + load.drivers[driver].driverName;
        }
      }
      return load;
    },
    isFiltersMatch (loadData) {
      let j = 0;
      let isExists = false;
      let _drivers = [];
      let _trucks = [];

      for (j = 0; j < loadData.locations.length - 1; j++) {
        if (loadData.locations[j].truck > 0) {
          _trucks.push(loadData.locations[j].truck);
        }
        if (loadData.locations[j].driver > 0) {
          _drivers.push(loadData.locations[j].driver);
        }
        if (loadData.locations[j].coDriver > 0) {
          _drivers.push(loadData.locations[j].coDriver);
        }
      }
      // Check filters
      if (this.hasOwnProperty('filterDriver') && parseInt(this.filterDriver) > 0) {
        if (_drivers.length === 0) return false;
        isExists = false;
        _drivers.forEach(value => {
          if (this.filterDriver.split(',').indexOf(value.toString()) >= 0) {
            isExists = true;
          }
        });
        if (!isExists) return false;
      }
      if (this.hasOwnProperty('filterTruck') && parseInt(this.filterTruck) > 0) {
        if (_trucks.length === 0) return false;
        isExists = false;
        _trucks.forEach(value => {
          if (this.filterTruck.split(',').indexOf(value.toString()) >= 0) {
            isExists = true;
          }
        });
        if (!isExists) return false;
      }
      if (this.hasOwnProperty('filterBroker') && parseInt(this.filterBroker) > 0) {
        if (this.filterBroker.split(',').indexOf(loadData.broker.toString()) < 0) {
          return false;
        }
      }
      return true;
    },
    fetchData (skipSelected = false, selected = []) {
      if (!this.from || !this.to) return;
      let _totalsCounts = this.getEmptyTotals();

      return new Promise((resolve, reject) => {
        let i = 0;
        this.loading = true;
        this.getLoadsReportData(this.from, this.to, 0, 0, this.dispatcherId).then((response) => {
          if (response.status === 'ok') {
            this.loads = [];
            this.currentDateTime = response.currentDateTime;
            let selectedArray = [];
            if (this.selected && !skipSelected) {
              selectedArray = this.selected.split(',').map(v => {
                return parseInt(v);
              });
            }
            if (selected.length > 0) {
              selectedArray = selected;
            }
            this.selectedItems = [];
            for (i = 0; i < response.loads.length; i++) {
              this.dispatcherName = response.loads[i].dispatcherName;
              if (selectedArray.length > 0 && selectedArray.indexOf(parseInt(response.loads[i].id)) < 0) continue;

              if (!this.isFiltersMatch(response.loads[i])) continue;
              const load = this.processLoad(response.loads[i], _totalsCounts);

              // Check filters
              if (this.hasOwnProperty('filterTruck') && parseInt(this.filterTruck) > 0) {
                if (load.trucks.length === 0) continue;
                let isExists = false;
                for (let fi = 0; fi < load.trucks.length; fi++) {
                  if (this.filterTruck.split(',').indexOf(load.trucks[fi].id.toString()) >= 0) {
                    isExists = true;
                    break;
                  }
                }
                if (!isExists) continue;
              }
              if (this.hasOwnProperty('filterDriver') && parseInt(this.filterDriver) > 0) {
                if (load.trucks.length === 0) continue;
                let isExists = false;
                for (let fi = 0; fi < load.trucks.length; fi++) {
                  for (let fj = 0; fj < load.trucks[fi].drivers.length; fj++) {
                    if (this.filterDriver.split(',').indexOf(load.trucks[fi].drivers[fj].id.toString()) >= 0) {
                      isExists = true;
                      break;
                    }
                  }
                  if (isExists) break;
                }
                if (!isExists) continue;
              }
              if (this.hasOwnProperty('filterBroker') && parseInt(this.filterBroker) > 0) {
                let isExists = false;
                if (this.filterBroker.split(',').indexOf(load.broker.toString()) >= 0) {
                  isExists = true;
                }
                if (!isExists) continue;
              }

              load.toPayAmount = load.dispatcherRateTotal;
              load.currentDateTime = this.currentDateTime;
              this.loads.push(load);

              _totalsCounts.totalMiles += parseInt(load.distanceInMiles);
              const _rate = parseFloat(load.Rate) + parseFloat(load.RTLRate);
              if (load.isShared) {
                _totalsCounts.totalLoadsRate += parseFloat(_rate / (load.sharedWith.length + 1));
                _totalsCounts.totalAdditionalPayments += parseFloat(load.additionalPayments / (load.sharedWith.length + 1));
                _totalsCounts.totalLoadsRateWithAdditionalPayments += parseFloat(_rate / (load.sharedWith.length + 1)) + parseFloat(load.additionalPayments / (load.sharedWith.length + 1));
              } else {
                _totalsCounts.totalLoadsRate += parseFloat(_rate);
                _totalsCounts.totalAdditionalPayments += parseFloat(load.additionalPayments);
                _totalsCounts.totalLoadsRateWithAdditionalPayments += parseFloat(_rate) + parseFloat(load.additionalPayments);
              }
              _totalsCounts.totalDispatcherPaymentFromRate += parseFloat(load.dispatcherRate);
              _totalsCounts.totalDispatcherPaymentFromBonus += parseFloat(load.dispatcherRateFromBonus);
              _totalsCounts.totalDispatcherRate += parseFloat(load.dispatcherRateTotal);
            }
            _totalsCounts.totalAvgLoadsRatePerMile = parseFloat(_totalsCounts.totalLoadsRateWithAdditionalPayments) / parseFloat(_totalsCounts.totalMiles);
            _totalsCounts.loadsCount = this.loads.length;

            this.$store.dispatch('reports/totalsCounts', _totalsCounts);
            this.$store.commit('reports/updateReportsGroupsListKey');
            this.onItemSelected();

            this.initReportsFilters();

            this.$forceUpdate();
            this.loading = false;
            resolve({
              loadsCount: this.totalsCounts.loadsCount,
              totalLoadsRate: this.totalsCounts.totalLoadsRate,
              totalAdditionalPayments: this.totalsCounts.totalAdditionalPayments,
              totalLoadsRateWithAdditionalPayments: this.totalsCounts.totalLoadsRateWithAdditionalPayments,
              totalAvgLoadsRatePerMile: this.totalsCounts.totalAvgLoadsRatePerMile,
              totalDispatcherPaymentFromRate: this.totalsCounts.totalDispatcherPaymentFromRate,
              totalDispatcherPaymentFromBonus: this.totalsCounts.totalDispatcherPaymentFromBonus,
              totalDispatcherRate: this.totalsCounts.totalDispatcherRate,
              loads: this.loads
            });
          }
        });
      }).finally(() => {
        this.loading = false;
      });
    }
  }
};
</script>
